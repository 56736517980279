import Header from "./Header";
import List from "../components/checkin/Form";
import Footer from "./Footer";

export default function Help() {
    return (
        <main>
            <Header title={"Hjälp och information"} subtitle={"Vi är glada att välkomna dig till vår lägenhet. Nedan hittar du viktig information för att göra din vistelse så bekväm som möjligt."}/>

            <div className="bg-amber-50_ mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8 md:w-128 ml-auto mr-auto w-full">

                <h2 className={"font-bold text-l"}>Använda appen:</h2>
                <ul className={"list-disc pl-8 my-4"}>
                    <li>Hjälp att använda appen</li>
                    <li>Etc.</li>
                </ul>

                    <h3 className={"font-bold text-l"}>Incheckning/Utcheckning:</h3>

                    <ul className={"list-disc pl-8 my-4"}>
                        <li>Incheckning: Efter kl 15:00</li>
                        <li>Utcheckning: Senast kl 11:00</li>

                    </ul>

                    <h3 className={"font-bold text-l"}>Lägenhetsbekvämligheter</h3>
                    <ul className={"list-disc pl-8 my-4"}>

                        <li>Kök: Fullt utrustat med spis, ugn, mikrovågsugn, kylskåp, kaffebryggare och diskmaskin. Grundläggande köksredskap, kastruller, stekpannor, tallrikar och bestick finns tillgängliga.</li>
                        <li>Värme/Kyla: Instruktioner för att justera termostaten finns på väggen nära köket.</li>
                        <li>Underhållning: Lägenheten inkluderar en TV med streamingtjänster. Instruktioner för användning finns bredvid fjärrkontrollen.</li>
                    </ul>

            </div>
            <Footer/>
        </main>
)

}
