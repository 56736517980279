import Header from "./Header";
import Footer from "./Footer";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function Checkindone(props){

    useEffect(() => {
        localStorage.setItem("isCheckedIn", true)
    }, []);
    const { t, i18n } = useTranslation();

    return (
        <div>
            <Header title={t("Thanks_you_are_now_checkedin")} subtitle={t("Start_welcome_to_sub_title")}/>
            <main>
                <div className="bg-amber-50_ mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 md:w-128 ml-auto mr-auto w-full">
                    <p>{t("Start_checkin")}</p>
                    <Link to={"/checkout"} className="block mt-4 items-center justify-center w-full sm:w-64 px-6 py-2 mb-3 text-base font-medium text-center text-white bg-button dark:bg-blue-600 hover:bg-button/80 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">{t("btn_Start_checkout")}</Link>
                    <p className={"mt-12"}>{t("Start_checkout")}</p>
                    <Link to={"/start"} className="block mt-4 items-center justify-center w-full sm:w-64 px-6 py-2 mb-3 text-base font-medium text-center text-white bg-button dark:bg-blue-600 hover:bg-button/80 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">{t("btn_Start_checkin")}</Link>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
