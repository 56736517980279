import Header from "./Header";
import ContactForm from "../components/ContactForm";

export default function CustomerService() {
    return (
        <div>
            <Header title={"Kundservice"} subtitle={"Om du behöver hjälp eller har några speciella önskemål, tveka inte att kontakta oss."}/>
            <main>
                <div className="bg-amber-50_ mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 md:w-128 ml-auto mr-auto w-full">

                    <ContactForm/>

                </div>
            </main>
        </div>
    )
}
