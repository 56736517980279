import Header from "./Header";
import Footer from "./Footer";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

export function Checkoutdone(props){

    useEffect(() => {
        localStorage.setItem("isCheckedIn", false)
    }, []);
    const { t, i18n } = useTranslation();

    return (
        <div>
            <Header title={t("Thanks_you_are_now_checkedout")} subtitle={t("Start_welcome_to_sub_title")}/>
            <main>
                <div className="bg-amber-50_ mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 md:w-128 ml-auto mr-auto w-full">
                    {/*<p>Börja utcheckningen genom att klicka på knappen nedan.</p>*/}
                    {/*<a href={"/checkout"} className="block mt-4 items-center justify-center w-full sm:w-64 px-6 py-2 mb-3 text-base font-medium text-center text-white bg-button dark:bg-blue-600 hover:bg-button/80 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">Starta utcheckning</a>*/}
                    {/*<p className={"mt-12"}>Börja incheckningen genom att klicka på knappen nedan."</p>*/}
                    {/*<a href={"/start"} className="block mt-4 items-center justify-center w-full sm:w-64 px-6 py-2 mb-3 text-base font-medium text-center text-white bg-button dark:bg-blue-600 hover:bg-button/80 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">Starta incheckning</a>*/}

                    <a className={"text-blue-800 hover:text-red-800"} href={"/start"}>{t("link_back_to_home")}</a>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
