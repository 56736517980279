import React, { useState } from 'react';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would normally handle form submission, e.g., sending the data to a server
        console.log('Form data submitted:', formData);
        setSubmitted(true);
    };

    return (
        <div className="p-8_ _font-sans">

            {/*<h1 className="text-3xl font-bold mb-4">Welcome to Your Home Away From Home!</h1>*/}
            {/*<p className="mb-6">We are delighted to welcome you to our apartment! Here are some important details to help you settle in and enjoy your stay:</p>*/}

            {/*<h2 className="text-2xl font-semibold mb-2">General Information</h2>*/}
            {/*<ul className="list-disc list-inside mb-6">*/}
            {/*    <li><strong>Check-In:</strong> After 3 PM</li>*/}
            {/*    <li><strong>Check-Out:</strong> By 11 AM</li>*/}
            {/*    <li><strong>Wi-Fi:</strong> Network Name: [Network Name], Password: [Password]</li>*/}
            {/*    <li><strong>Contact:</strong> Call [Phone Number] or email [Email Address]</li>*/}
            {/*</ul>*/}

            <h2 className="text-xl font-semibold mb-4">Kontakta oss</h2>

            <p >För ytterligare hjälp, kontakta vår kundtjänst på support@exempelapp.com eller ring oss på 123-456 7890.</p>


            <p className="mb-4">Om du har några frågor eller behöver hjälp, fyll i formuläret nedan så återkommer vi till dig så snart som möjligt.</p>
            {submitted ? (
                <p className="text-green-500 font-semibold">Tack för ditt meddelande! Vi återkommer till dig inom kort.</p>
            ) : (
                <form onSubmit={handleSubmit} className="flex flex-col  mx-auto">
                    {/*<label className="mb-2">*/}
                    {/*    Namn:*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        name="name"*/}
                    {/*        value={formData.name}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        required*/}
                    {/*        className="mt-1 mb-4 p-2 border border-gray-300 rounded w-full"*/}
                    {/*    />*/}
                    {/*</label>*/}
                    {/*<label className="mb-2">*/}
                    {/*    Epost:*/}
                    {/*    <input*/}
                    {/*        type="email"*/}
                    {/*        name="email"*/}
                    {/*        value={formData.email}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        required*/}
                    {/*        className="mt-1 mb-4 p-2 border border-gray-300 rounded w-full"*/}
                    {/*    />*/}
                    {/*</label>*/}
                    <label className="mb-2">
                        Meddelande:
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            rows="4"
                            className="mt-1 mb-4 p-2 border border-gray-300 rounded w-full"
                        ></textarea>
                    </label>
                    <button type="submit" className="p-2 button text-white rounded  w-full">Skicka</button>
                </form>
            )}
        </div>
    );
};

export default ContactForm;
