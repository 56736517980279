import {
    DialogBackdrop,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'
import LanguageMenu from "../components/LanguageMenu";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


const user = {
    name: 'Namn Namnsson',
    email: 'tom@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
    // { name: 'Dashboard', href: '#', current: true },
    // { name: 'Team', href: '#', current: false },
    // { name: 'Contact', href: '#', current: false },
    {name: 'menu_customerservice', href: '/customerservice', current: false},
    {name: 'menu_information', href: '/help', current: false},
    {name: 'menu_log_out', href: '/', current: false},

]
const userNavigation = [
    {name: 'Div profil', href: '#'},
    {name: 'Inställningar', href: '#'},
    {name: 'Logga ut', href: '#'},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header(props) {
    const { t, i18n } = useTranslation();

    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
            <div className="min-h-full">
                <Disclosure as="nav" className="bg-gray-100 shadow ">
                    {({open}) => (
                        <>
                            <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <Link to={"/start"}>
                                                <img
                                                    className="h-12_ w-28"
                                                    src="images/logo.svg"
                                                    alt="Idre Fjäll Logo"
                                                />
                                            </Link>
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map((item) => (
                                                    <Link
                                                        key={item.name}
                                                        to={item.href}
                                                        className={classNames(
                                                            item.current
                                                                ? 'bg-gray-900 text-white'
                                                                : 'text-gray-900 hover:bg-gray-200 _hover:text-white',
                                                            'rounded-md px-3 py-2 text font-bold',
                                                        )}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {t(item.name)}
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">

                                            <LanguageMenu/>
                                            {/*<button*/}
                                            {/*    type="button"*/}
                                            {/*    className="relative rounded-full bg-gray-300 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"*/}
                                            {/*>*/}
                                            {/*    <span className="absolute -inset-1.5" />*/}
                                            {/*    <span className="sr-only">View notifications</span>*/}
                                            {/*    <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                                            {/*</button>*/}

                                            {/* Profile dropdown */}
                                            {/*<Menu as="div" className="relative ml-3">*/}
                                            {/*    <div>*/}
                                            {/*        <MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">*/}
                                            {/*            <span className="absolute -inset-1.5" />*/}
                                            {/*            <span className="sr-only">Open user menu</span>*/}
                                            {/*            <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />*/}
                                            {/*        </MenuButton>*/}
                                            {/*    </div>*/}
                                            {/*    <MenuItems*/}
                                            {/*        transition*/}
                                            {/*        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"*/}
                                            {/*    >*/}
                                            {/*        {userNavigation.map((item) => (*/}
                                            {/*            <MenuItem key={item.name}>*/}
                                            {/*                {({ focus }) => (*/}
                                            {/*                    <a*/}
                                            {/*                        href={item.href}*/}
                                            {/*                        className={classNames(*/}
                                            {/*                            focus ? 'bg-gray-100' : '',*/}
                                            {/*                            'block px-4 py-2 text-sm text-gray-700',*/}
                                            {/*                        )}*/}
                                            {/*                    >*/}
                                            {/*                        {item.name}*/}
                                            {/*                    </a>*/}
                                            {/*                )}*/}
                                            {/*            </MenuItem>*/}
                                            {/*        ))}*/}
                                            {/*    </MenuItems>*/}
                                            {/*</Menu>*/}
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        <div className={"px-4"}><LanguageMenu/></div>
                                        {/* Mobile menu button */}
                                        <DisclosureButton className="relative inline-flex items-center justify-center rounded-md bg-gray-200 p-2 text-gray-800 hover:bg-gray-300 _hover:text-white ">
                                            <span className="absolute -inset-0.5"/>
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                            )}
                                        </DisclosureButton>
                                    </div>
                                </div>
                            </div>

                            {/* MOBILE MENU */}
                            <DisclosurePanel className="absolute w-full bg-gray-100 md:hidden z-50 border-b shadow">
                               
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    {navigation.map((item) => (
                                        <DisclosureButton
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            className={classNames(
                                                item.current ? 'bg-gray-900 text-white' : 'text-gray-900 hover:bg-gray-200 _hover:text-white',
                                                'block rounded-md px-3 py-2 text-base font-medium',
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {t(item.name)}
                                        </DisclosureButton>
                                    ))}
                                </div>
                                <div className="border-t_ _border-gray-700 pb-3 pt-4">
                                    <div className="flex items-center px-5">

                                    </div>

                                </div>

                            </DisclosurePanel>
                        </>
                    )}
                </Disclosure>

                <header className="bg-white shadow ">
                    <div className={"mx-auto max-w-7xl md:w-128  w-full"}>
                        <div className="mx-auto max-w-7xl px-4 py-2 sm:py-6 sm:px-6 lg:px-8 md:w-main w-full">
                            <h1 className="sm:text-3xl text-l font-bold text-gray-900">{props.title}</h1>
                            <p className={"mt-2 text-sm"}>{props.subtitle}</p>
                        </div>
                    </div>
                </header>
                {/*<main>*/}
                {/*    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">*/}
                {/*        df*/}
                {/*    </div>*/}
                {/*</main>*/}
            </div>
        </>
    )
}
