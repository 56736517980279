import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BsCheckCircleFill, BsXCircleFill} from "react-icons/bs";
import {Dialog, DialogBackdrop, DialogPanel} from "@headlessui/react";
import {RotatingLines} from "react-loader-spinner";
import {items} from "./List"
import {useTranslation} from "react-i18next";


function List({
                  item,
                  listItemNumber,
                  setListItemShowNumber,
                  showNumber,
                  setShowNumber,
                  index,
                  updateFormData,
                  ...rest
              }) {

    if (index === listItemNumber) {
        return (
            <div>
                <div className={"text-l sm:text-2xl font-bold text-center mb-4 my-3 flex items-center justify-center gap-2 leading-normal"}>
                    <p>{item.icon}</p>
                    <p>{item.title}</p>
                </div>
                {item.list.map((item, i) => <div key={item.text}>
                    <ListItem updateFormData={updateFormData} listItemNumber={listItemNumber} setListItemShowNumber={setListItemShowNumber} setShowNumber={setShowNumber} showNumber={showNumber} index={i} item={item}/>
                </div>)}
            </div>
        )
    }
}

function ListItem({
                      item,
                      index,
                      setShowNumber,
                      showNumber,
                      setListItemShowNumber,
                      listItemNumber,
                      updateFormData,
                      ...rest
                  }) {
    const [showTextArea, setShowTextArea] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     console.log(message)
    // }, [message]);

    const yesClickHandler = (e) => {

        updateFormData(item, "result", true);
        e.preventDefault();
        showTransition()
            .then(() => {
                setShowNumber(showNumber + 1)
            })

    }
    const noClickHandler = (e) => {
        e.preventDefault();
        if (item.showTextarea) {
            setShowTextArea(true)
        } else {
            showTransition()
                .then(() => {
                    setShowNumber(showNumber + 1)
                })
        }

    }
    const commentClickHandler = (e) => {
        e.preventDefault();
        showTransition()
            .then(() => {
                setShowNumber(showNumber + 1)
                setShowTextArea(false)
                item.result = false;
                updateFormData(item, "message", message);
            })
    }

    const showTransition = () => {
        setIsOpen(true)
        return new Promise(resolve => setTimeout(() => resolve(setIsOpen(false)), 300))
    }


    if (index === showNumber) {
        return (
            <div className={"text-center mt-2"}>
                <div className={"font-bold text-blue-800"}>{index + 1} {t("of")} {items[i18n.language][listItemNumber]?.list.length}</div>

                <div className={"font-bold w-full sm:w-96 m-auto text-l sm:text-xl"}><p className={"leading-normal"}>{item.text}</p></div>
                <div className={"w-64 text-center m-auto mt-2"}>{item.help}</div>
                <div className={`${showTextArea ? "block" : "hidden"} mt-10`}>
                    <p className={"font-bold mb-2"}>{t("Checkin_form_message")}</p>
                    <textarea onChange={(e) => setMessage(e.target.value)} id="message" rows="4" className={`w-full  m-auto sm:w-96 block p-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:focus:border-blue-500`} placeholder={t("please_leave_a_comment")}></textarea>
                    <button onClick={commentClickHandler} className={"button m-auto inline-flex mt-6 items-center justify-center w-full sm:w-96 px-6 py-2 mb-3 text-base font-medium text-center text-white  rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700  md:mb-0"}>{t("Save")}</button>
                </div>

                <div className={`${!showTextArea ? "block" : "hidden"} flex justify-center align-middle gap-32 
                    
                    sm:relative sm:bottom-0 sm:mt-20
                    absolute bottom-10 left-1/2 transform -translate-x-1/2`

                }>
                    <button onClick={noClickHandler}>
                        <BsXCircleFill className={'drop-shadow-md h-14 w-14 bg-white rounded-full text-[#b72519] hover:text-[#b72519]/80'}/><span className={"font-bold"}>{t("NO")}</span>
                    </button>
                    <button onClick={yesClickHandler}>
                        <BsCheckCircleFill className={'drop-shadow-md h-14 shadow w-14 bg-white rounded-full text-[#5dc5b7] hover:text-[#5dc5b7]/80'}/><span className={"font-bold"}>{t("YES")}</span>
                    </button>
                </div>


                <Dialog open={isOpen} onClose={() => null} className="relative z-50">
                    <DialogBackdrop className="fixed inset-0 bg-black/30"/>
                    <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                        <DialogPanel className="max-w-lg space-y-4 bg-white p-8 rounded shadow">
                            <RotatingLines
                                visible={true}
                                height="32"
                                width="32"
                                color="blue"
                                strokeWidth="5"
                                animationDuration="0.90"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </DialogPanel>
                    </div>
                </Dialog>
            </div>
        )
    } else return (<></>)
}

export default function Form() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate()
    const [showNumber, setShowNumber] = useState(0);
    const [listItemNumber, setListItemShowNumber] = useState(0);
    const [formData, setFormData] = useState(items[i18n.language])


    const updateFormData = (item, param, value) => {

        let listIndex = formData.findIndex(list => list.id === listItemNumber + 1)
        let itemIndex = formData[listIndex].list.findIndex(item => item.id === showNumber + 1)
        let formDataCopy = formData
        formDataCopy[listIndex].list[itemIndex].param = value;
        setFormData(formDataCopy)
    }

    useEffect(() => {
        if (listItemNumber === items[i18n.language].length) {
            localStorage.setItem("isCheckedIn", true);
            // Send formData to server
            navigate("/checkindone")
        }
    }, [listItemNumber])


    useEffect(() => {
        if (showNumber > items[i18n.language][listItemNumber]?.list.length - 1) {
            setShowNumber(0)
            setListItemShowNumber(listItemNumber + 1)
        }
    }, [showNumber])

    return (
        <div>
            <div>
                <legend className="sr-only">Checklista utcheckning</legend>
                {items[i18n.language].map((item, i) => <div key={item.title}>
                    <List updateFormData={updateFormData} listItemNumber={listItemNumber} setListItemShowNumber={setListItemShowNumber} setShowNumber={setShowNumber} showNumber={showNumber} index={i} item={item}/>
                </div>)}
            </div>
            {/*<button href={"/checkinsubmit"} className="block mt-4 items-center justify-center w-full sm:w-44 px-6 py-2 mb-3 text-base font-medium text-center text-white bg-button dark:bg-blue-600 hover:bg-button/80 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">Submit</button>*/}
        </div>
    )
}


