import {FaBath, FaBed, FaChair, FaKitchenSet} from "react-icons/fa6";



export const items = {
    se:[
        {
            id: 1,
            title: "Kök",
            icon: <FaKitchenSet/>,
            list: [
                {
                    id: 1,
                    text: "Är kylskåp och frys städat?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 2,
                    text: "Är glas, bestick och porslin rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 3,
                    text: "Är diskmaskin, mikrovågsugn, köksspis, ugn och ugnsplåtar rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 4,
                    text: "Är kaffebryggare och brödrost rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 5,
                    text: "Är köksfläktilter rengjort?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 6,
                    text: "Är avfallskärl och skåp rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
            ]
        },
        {
            id: 2,
            title: "Sovrum",
            icon: <FaBed/>,
            list: [
                {
                    id: 1,
                    text: "Är Skåp, lådor och garderober rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 2,
                    text: "Är sängkläderna på sängarna vikta?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
            ]
        },
        {
            id: 3,
            title: "Badrum",
            icon: <FaBath/>,
            list: [
                {
                    id: 1,
                    text: "Är handfat, toalett rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 2,
                    text: "Är tvättställ och vattenkranar rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 3,
                    text: "Är kakel och speglar rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 4,
                    text: "Är golvbrunn i dusch och bastu rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
            ]
        },
        {
            id: 4,
            title: "Allmänna utrymmen",
            icon: <FaChair/>,
            list: [
                {
                    id: 1,
                    text: "Är soporna slängda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 2,
                    text: "Är golven skurade?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 3,
                    text: "Är torkskåp rengjorda?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                },
                {
                    id: 4,
                    text: "Är öppen spis rengjord?",
                    help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                    showTextarea: true
                }
            ]
        },
    ],
    en:[
        {
            id: 1,
            title: "Kitchen",
            icon: <FaKitchenSet />,
            list: [
                {
                    id: 1,
                    text: "Is the refrigerator and freezer cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 2,
                    text: "Are glasses, cutlery, and porcelain cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 3,
                    text: "Are the dishwasher, microwave, stove, oven, and baking trays cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 4,
                    text: "Are the coffee maker and toaster cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 5,
                    text: "Is the kitchen fan filter cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 6,
                    text: "Are the waste bins and cabinets cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
            ]
        },
        {
            id: 2,
            title: "Bedroom",
            icon: <FaBed />,
            list: [
                {
                    id: 1,
                    text: "Are cupboards, drawers, and wardrobes cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 2,
                    text: "Are the bed linens on the beds folded?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
            ]
        },
        {
            id: 3,
            title: "Bathroom",
            icon: <FaBath />,
            list: [
                {
                    id: 1,
                    text: "Are the sink and toilet cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 2,
                    text: "Are the washbasin and faucets cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 3,
                    text: "Are the tiles and mirrors cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 4,
                    text: "Is the floor drain in the shower and sauna cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
            ]
        },
        {
            id: 4,
            title: "Common Areas",
            icon: <FaChair />,
            list: [
                {
                    id: 1,
                    text: "Is the trash thrown out?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 2,
                    text: "Are the floors scrubbed?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 3,
                    text: "Are the drying cabinets cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                },
                {
                    id: 4,
                    text: "Is the fireplace cleaned?",
                    help: "This help text is intended to give users an overview and guidance.",
                    showTextarea: true
                }
            ]
        },
    ]
}

